import { IonItem, IonGrid, IonRow, IonCol } from '@ionic/react';
import React, {} from 'react';


const InstallListItem: React.FC<any> = (props:any) => {
  let marker = props.marker;
  function mouseEnter(e: any, marker: any) {
    let delay = setTimeout(() => {
      let ele = document.querySelector(`img[src*="${marker.store}${marker.install.Address}${marker.install.City}marker"]`);
      if (ele && ele.parentElement) {
        ele.classList.add("hoveredInstall")
        ele.parentElement.classList.add("hoveredInstall")
      }
      if (marker.setAnimation)
        marker.setAnimation(google.maps.Animation.BOUNCE);
    }, 50);
    marker.delay = delay;
  }
  function mouseLeave(e: any, marker: any) {
    if (marker.setAnimation)
      marker.setAnimation();
    if (marker.delay)
      clearTimeout(marker.delay)
    let ele = document.querySelector(`img[src*="${marker.store}${marker.install.Address}${marker.install.City}marker"]`);
    if (ele && ele.parentElement)
      ele.parentElement.classList.remove("hoveredInstall")
    setTimeout(() => {
      marker.setAnimation();
      let ele = document.querySelector(`img[src*="${marker.store}${marker.install.Address}${marker.install.City}marker"]`);
      if (ele && ele.parentElement)
        ele.parentElement.classList.remove("hoveredInstall")
    }, 500)
  }
  return (
    <IonItem
      id={marker.key}
      onClick={(e) => {props.onClick(e, marker)}}
      button class="installHover"
      onMouseEnter={(e) => {mouseEnter(e, marker);}}
      onMouseLeave={(e) => {mouseLeave(e, marker);}}>
      <IonGrid >
        <IonRow class="ion-align-items-center">
            <IonCol class="paddedColumn" size="auto">
              {marker.install.Store}
            </IonCol>
            <IonCol>
              {marker.install.City + ", "}
              {marker.install.State}
            </IonCol>
            {marker.install["Install Team"] !== "" && props.type !== "selected" ?
              (<IonCol class="ion-text-center">
                {marker.install["Install Team"]}
              </IonCol>
            ): ""}
            {marker.install["Install Date"] !== "" && props.type !== "selected" ?
              (<IonCol class="ion-text-center">
                {marker.install["Install Date"]}
              </IonCol>
            ): ""}
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default InstallListItem;
