import { IonContent, IonGrid, IonRow, IonCol, IonButton, IonItem } from '@ionic/react';
import React, {} from 'react';
import './MenuVisibility.scss';

interface StringIter {
  [index: string]: any,
}


const MenuVisibility: React.FC<any> = ({mapMarkers, projectNames, projectIsSelected, setProjectIsSelected}) => {

  return (
    <IonContent>
      <IonItem>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonButton
              expand="block"
              class={projectIsSelected.scheduled ? "selected" : "notSelected"}
              onClick={()=>setProjectIsSelected({...projectIsSelected, scheduled: !projectIsSelected.scheduled})}>
              Scheduled
              </IonButton>
          </IonCol>
          <IonCol>
            <IonButton
              expand="block"
              class={projectIsSelected.unscheduled ? "selected" : "notSelected"}
              onClick={()=>setProjectIsSelected({...projectIsSelected, unscheduled: !projectIsSelected.unscheduled})}>
              Unscheduled
            </IonButton>
          </IonCol>
        </IonRow>
        </IonGrid>
        </IonItem>
        <IonGrid>
        <IonRow>
          {projectNames.map((project: any, index: any) => {
            return (<IonCol key={project}>
              <IonButton
                class={projectIsSelected[project] ? "selected" : "notSelected"}
                onClick={()=>setProjectIsSelected({...projectIsSelected, [project]: !projectIsSelected[project]})}
                text-capitalize="false"
                size="small"
                expand="block">
                {project}
              </IonButton>
            </IonCol>)
          })}
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default MenuVisibility;
