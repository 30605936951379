import { IonContent, IonItem, IonLabel, IonCard, IonIcon, IonButton, IonModal, IonGrid, IonRow, IonCol, IonRadio, IonRadioGroup } from '@ionic/react';
import React, {useRef, useEffect} from 'react';
import InstallListItem from '../components/InstallListItem';
import './MenuInstalls.scss';
import { menu } from 'ionicons/icons';

import Icon from '@mdi/react'
import * as Icons from '@mdi/js'


interface StringIter {
  [index: string]: any,
}


const MenuInstalls: React.FC<any> = (
  {
    visible, setVisible, allHidden, mapMarkers, setMapMarkers, projectNames, setProjectNames, setIcon,
    projectIsSelected, setProjectIsSelected, visibleProjects, installsClick, savedIconState,
    modalVisible, setModalVisible, selectedIcon, setSelectedIcon, selectedColor, setSelectedColor, editTarget, setEditTarget}) => {



  let style = useRef(document.createElement('style'));
  useEffect(() => {
    style.current.type = 'text/css';
    style.current.innerHTML = '.height { height: 200px; }';
    document.getElementsByTagName('head')[0].appendChild(style.current);
  }, [])

  useEffect(() => {
    var interval = setInterval(() => {
      setHeight();
    }, 100);
    function setHeight() {
      let ele = document.getElementById('container');
      if (ele && window.getComputedStyle(ele).height !== "auto") {
        let totalHeight = parseInt(window.getComputedStyle(ele).height)
        let headerCount = visibleProjects.length;
        let height = totalHeight - (headerCount * 48);
        style.current.innerHTML = `.height { height: ${height}px; }`;
        clearInterval(interval);
      }
    }

  })
  let iconNames:StringIter = [
    "mdiMarkerCheck",
    "mdiMarkerCheckOutline",
    "mdiMapMarker",
    "mdiMapMarkerOutline",
    "mdiMapMarkerCheck",
    "mdiMapMarkerCheckOutline",
    "mdiMapMarkerAlert",
    "mdiMapMarkerAlertOutline",
    "mdiMapMarkerDown",
    "mdiMapMarkerDownOutline",
  ]
  let iconIter: StringIter = Icons
  let iconArr:any = [];
  for (let name in iconNames) {
    if (iconIter[iconNames[name]] !== undefined)
      iconArr.push({icon: iconIter[iconNames[name]], name: iconNames[name]})
  }


  return (
    <IonContent id="container">
      {visibleProjects.map((project:any, index:any) => {
        return (
          <div key={"div"+index}>
            <IonItem
              class="ion-text-center"
              color="primary"
              button onClick={(e)=>{
                e.stopPropagation();
                setVisible({...allHidden, [project]: !visible[project]})}
              }>
              {savedIconState[project] ?
              <Icon path={savedIconState[project].icon}
                      title={"icon"}
                      size={1.5}
                      color={savedIconState[project].color}
                      className="whiteBackground"
                      />
              : ""}
              <IonLabel>
                {mapMarkers.projectDisplaynames[project]}
              </IonLabel>
              <IonButton id={"edit-"+project} onClick={(e:any) => {e.stopPropagation(); setModalVisible(true); setEditTarget(e.currentTarget.id.split("-")[1]);}}><IonIcon icon={menu}/></IonButton>
            </IonItem>
            <IonCard id={project + "accordion"} class={visible[project] ? "height accordion visible" : "accordion"}>
              {[...(projectIsSelected.scheduled ? mapMarkers[project].scheduled : []),
                ...(projectIsSelected.unscheduled ? mapMarkers[project].unscheduled : [])
              ].map((marker: any, index: any) => {
                return marker.install ? (
                  <InstallListItem
                    onClick={installsClick}
                    key={marker.key}
                    index={index}
                    marker={marker}
                    map={mapMarkers.map}
                  />
                ) : "";
              })}
            </IonCard>
          </div>
        )
      })}
      <IonModal isOpen={modalVisible}>
        <IonItem
          class="ion-text-center"
          color="primary">
          <IonLabel>
            Set Icon
          </IonLabel>
        </IonItem>
        <IonGrid>
        <IonRadioGroup onIonChange={(e) => {setSelectedIcon(e.detail.value)}}>
          <IonRow>
            {iconArr.map((icon:any, index:any) => {
              return (
            <IonCol key={icon.name}>
              <IonItem  lines="none" >
              <Icon path={icon.icon}
                      title={icon.name}
                      size={2}
                      />
              <IonRadio slot="start" value={icon.name} />
              </IonItem>
            </IonCol>)
            })}
          </IonRow>
          </IonRadioGroup>
          <IonRadioGroup onIonChange={(e) => {setSelectedColor(e.detail.value)}}>
            <IonRow>
            <IonCol>
              <IonItem lines="none" class="markerColorRed">
                <IonLabel>Red</IonLabel>
              <IonRadio slot="start" value="red" />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="none"  class="markerColorBlue">
                <IonLabel>Blue</IonLabel>
              <IonRadio slot="start" value="blue" />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="none"  class="markerColorGreen">
                <IonLabel>Green</IonLabel>
              <IonRadio slot="start" value="green" />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="none"  class="markerColorYellow">
                <IonLabel>Yellow</IonLabel>
              <IonRadio slot="start" value="yellow" />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="none"  class="markerColorOrange">
                <IonLabel>Orange</IonLabel>
              <IonRadio slot="start" value="orange" />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="none"  class="markerColorPurple">
                <IonLabel>Purple</IonLabel>
              <IonRadio slot="start" value="purple" />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="none"  class="markerColorPink">
                <IonLabel>Pink</IonLabel>
              <IonRadio slot="start" value="pink" />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="none"  class="markerColorAqua">
                <IonLabel>Aqua</IonLabel>
              <IonRadio slot="start" value="aqua" />
              </IonItem>
            </IonCol>
            </IonRow>
            </IonRadioGroup>
        </IonGrid>
        <IonGrid>
        <IonRow>
          <IonCol>
            <IonButton onClick={() => setModalVisible(false)}>Cancel</IonButton>
          </IonCol>
          <IonCol>
            <IonButton onClick={setIcon}>Set</IonButton>
          </IonCol>
        </IonRow>
        </IonGrid>
      </IonModal>
    </IonContent>
  );
};

export default MenuInstalls;
