import { IonContent, IonHeader, IonToolbar, IonTitle, IonPage, IonGrid, IonRow, IonCol, IonList, IonItem,
  IonIcon, IonButton} from '@ionic/react';
import React, {useState, useEffect, useRef} from 'react';
import { close } from 'ionicons/icons';
import SelectedListItem from './SelectedListItem';

import RouteManager from '../data/RouteManager';

import Draggable from 'react-draggable';

import './FloatingDialog.scss';

const FloatingDialog: React.FC<any> = ({appColors, mapMarkers, dialogBoxIsVisible, setDialogBoxIsVisible, selectedInstalls, setSelectedInstalls}) => {
  let [position, setPosition] = useState({x: 0, y: 0});
  let [directionsResults, setDirectionsResults] = useState<string[]>([])

  let style = useRef(document.createElement('style'));
  useEffect(() => {
    style.current.type = 'text/css';
    style.current.innerHTML = '.height { height: 200px; }';
    document.getElementsByTagName('head')[0].appendChild(style.current);
  }, [])

  useEffect(() => {
    if (140 + (selectedInstalls.length * 46) < document.body.clientHeight - 50)
      style.current.innerHTML = `.dialogBoxHeight { height: ${140 + (selectedInstalls.length * 46)}px; }`;
  })


  let bounds = {top: 0, left: 0, right: document.body.clientWidth-250, bottom: document.body.clientHeight-400}
  let startPosition = {x: document.body.clientWidth-250, y: 0}

  let handleDrag = (e:any, position: any) => {
    setPosition({x: position.x, y: position.y});
  }


  let selectedClick = (e: any, marker: any) => {
    if (marker.delay)
      clearTimeout(marker.delay)
    if (marker.setAnimation)
      marker.setAnimation();
    let ele = document.querySelector(`img[src*="${marker.store}${marker.install.Address}${marker.install.City}marker"]`);
    if (ele && ele.parentElement) {
      ele.parentElement.classList.remove("hoveredInstall")
      ele.parentElement.classList.remove("selectedInstall")
    }
    if (typeof marker.icon === "object") {
      marker.icon.path = marker.icon.defaultPath
      marker.icon.scale = 1.5;
      marker.setMap(null);
      marker.setMap(mapMarkers.map);
    }
    setTimeout(() => {
      marker.setAnimation();
      let ele = document.querySelector(`img[src*="${marker.store}${marker.install.Address}${marker.install.City}marker"]`);
      if (ele && ele.parentElement)
        ele.parentElement.classList.remove("hoveredInstall")
      marker.setMap(null);
      marker.setMap(mapMarkers.map);
    }, 500)
    setSelectedInstalls(selectedInstalls.filter((item:any) => item.key !== marker.key))
  }


  let route = useRef(new RouteManager());

  let mapIt = () => {
    if (selectedInstalls.length > 1) {
      let points = selectedInstalls.map((a:any) => a.position);
      route.current.getOptimizedRoute(points, selectedInstalls[0].map, true, setDirectionsResults);
    }
  };
  let clearIt = () => {
    var elems = document.querySelectorAll(".selectedInstall");

    [].forEach.call(elems, function(el:any) {
        el.classList.remove("selectedInstall");
    });
    selectedInstalls.forEach((marker: any, index: any) => {
      if (typeof marker.icon === "object") {
        marker.icon.path = marker.icon.defaultPath
        marker.icon.scale = 1.5;
        marker.setMap(null);
        marker.setMap(mapMarkers.map);
      }
    })

    route.current.clear();
    setSelectedInstalls([]);
  }
  useEffect(() => {
    setDirectionsResults([]);
    route.current.clear();
  }, [selectedInstalls])


  let [draggedItem, setDraggedItem] = useState<any>(null);
  let [draggedOverIndex, setDraggedOverIndex] = useState<number>(-1);
  let [draggedOverEle, setDraggedOverEle] = useState<any>(null);

  let onDragOver = (e:any, index:any) => {
    e.preventDefault();
    let eles:any = document.getElementsByClassName("draggable");
    for (let i = 0; i < eles.length; i++) {
      eles[i].style.background = ""
      eles[i].style.color = ""
    }
    if (e.target.classList.contains("draggable")) {
      e.target.style.background = appColors["--ion-color-step-900"];
      e.target.style.color = appColors["--ion-color-step-100"];
    }
    e.dataTransfer.dropEffect = "move";
    setDraggedOverIndex(index);
    setDraggedOverEle(e.target);
  };


  let onDragStart = (e:any, index:any) => {
    e.dataTransfer.effectAllowed = "move";
    e.target.style.opacity = ".5";
    setDraggedItem(selectedInstalls[index]);
  };
  let onDragEnd = (e: any) => {
    e.preventDefault();
    e.target.style.opacity = "";
    let eles:any = document.getElementsByClassName("draggable");
    for (let i = 0; i < eles.length; i++) {
      eles[i].style.background = ""
      eles[i].style.color = ""
    }
    draggedOverEle.style.background = appColors["--ion-color-danger"];
    draggedOverEle.style.color = appColors["--ion-color-danger-contrast"];

    let items = selectedInstalls.filter((item:any) => item.key !== draggedItem.key);
    items.splice(draggedOverIndex, 0, draggedItem);
    setSelectedInstalls([...items]);
  };



  return (
        <Draggable bounds={bounds} position={position.x === 0 ? startPosition : position} onDrag={handleDrag} handle="#header">
        <IonPage id="dialogBox" className={dialogBoxIsVisible ? "mydiv dialogBoxHeight noSelect" : "mydiv ion-hide dialogBoxHeight noSelect"}>
          <IonHeader id="header">
            <IonToolbar color="primary">
              <IonTitle size="small" >Route Info</IonTitle>
              <IonButton color="secondary" slot="end" fill="clear" onClick={() => setDialogBoxIsVisible(false)}>
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonToolbar>
            <IonItem>
            <IonGrid>
              <IonRow class="ion-align-items-center">
                <IonCol class="ion-text-center">
                  <IonButton onClick={mapIt}>Map It</IonButton>
                </IonCol>
                <IonCol class="ion-text-center">
                  {selectedInstalls.length}
                </IonCol>
                <IonCol class="ion-text-center">
                  <IonButton onClick={clearIt}>Clear</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            </IonItem>
          </IonHeader>
          <IonContent>
            <IonList>
            {selectedInstalls.map((marker: any, index:any) => {
              return <SelectedListItem
                        appColors={appColors}
                        onDragOver={(e: any) => onDragOver(e, index)}
                        onDragStart={(e:any) => onDragStart(e, index)}
                        onDragEnd={onDragEnd}
                        key={index + "selectedInstalls"}
                        marker={marker}
                        type="selected"
                        directions={directionsResults[index] ? directionsResults[index] : "-"}
                        onClick={selectedClick}/>
            })}
            </IonList>
          </IonContent>
          <>
            <div id="output" className="ion-hide"></div>
          </>
        </IonPage>
      </Draggable>
  );
};

export default FloatingDialog;
