import { IonContent, IonMenuButton, IonFab, IonPage } from '@ionic/react';
import React, {useEffect} from 'react';

import './MapView.scss';
import Map from '../components/Map';

const MapView: React.FC<any> = ({mapMarkers, setMapMarkers, setSelectedInstalls, selectedInstalls, setDialogBoxIsVisible}) => {

  useEffect(() => {
    for (let project in mapMarkers) {
      if (mapMarkers[project].scheduled) {
        [...mapMarkers[project].scheduled, ...mapMarkers[project].scheduled].forEach((marker, index) => {
          marker.addListener('click', (e:any) => {mouseEnter(marker)});
        });
      }
    }
  }, [mapMarkers])

  function mouseEnter(marker: any) {
//    setContent("asdf")
//    setVisible(true)
  }
  return (
    <IonContent id="mapContent">
      <IonFab vertical="top" horizontal="start" slot="fixed">
        <IonMenuButton class="ionMenuButton"></IonMenuButton>
      </IonFab>
      <IonPage>
        <Map {...{setMapMarkers, setSelectedInstalls, selectedInstalls, setDialogBoxIsVisible}}/>
      </IonPage>
    </IonContent>
  );
};

export default MapView;
