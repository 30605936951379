import { IonContent, IonMenu, IonTabs, IonRouterOutlet,
        IonIcon, IonLabel, IonTabButton, IonTabBar } from '@ionic/react';
import React, {useState, useEffect} from 'react';
import { Route } from 'react-router';
import { IonReactRouter } from '@ionic/react-router';
import {funnel, filing} from 'ionicons/icons';

import MenuInstalls from '../pages/MenuInstalls';
import MenuVisibility from '../pages/MenuVisibility';

import './Menu.scss';

import * as Icons from '@mdi/js'

import {setBounds} from '../data/MapData';

import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

interface StringIter {
  [index: string]: any,
}


const Menu: React.FC<any> = ({setMapMarkers, mapMarkers, setDialogBoxIsVisible, setSelectedInstalls, selectedInstalls}) => {
  let [activeTab, setActiveTab] = useState("installs");
  let [projectNames, setProjectNames] = useState<string[]>([]);
  let [projectIsSelected, setProjectIsSelected] = useState<StringIter>({});
  let [visibleProjects, setVisibleProjects] = useState<string[]>([])

  let [modalVisible, setModalVisible] = useState(false);
  let [selectedIcon, setSelectedIcon] = useState("");
  let [selectedColor, setSelectedColor] = useState("");
  let [editTarget, setEditTarget] = useState("");

  let [savedIconState, setSavedIconState] = useState<StringIter>({});

  let colors:StringIter = {
    red: "#e04a63",
    green: "#2ce63e",
    blue: "#268bd2",
    orange: "#e6922c",
    purple: "#832ce6",
    pink: "#e62cc4",
    aqua: "#2ce6cd",
    yellow: "#e6e62c",
  }

  let setIcon = (e:any, inputState?: any) => {
    let iconIter: StringIter = Icons;
    setModalVisible(false);
    let project = inputState ? inputState.project : editTarget;
    let localMarkers = {...mapMarkers};
    for (let scheduledStatus in localMarkers[project]) {
      // eslint-disable-next-line
      localMarkers[project][scheduledStatus].forEach((marker: any) => {
        let iconPath = marker.warehouse === true ? iconIter["mdiWarehouse"] : inputState ? inputState.icon : iconIter[selectedIcon];
        var icon = {
            path: iconPath,
            fillColor: inputState ? inputState.color : colors[selectedColor],
            fillOpacity: .8,
            anchor: new google.maps.Point(10,25),
            strokeWeight: .5,
            scale: 1.5
        }
        marker.icon = icon;
        marker.setMap(null);
        marker.setMap(mapMarkers.map);
      })
    }
    if (!inputState) {
      setMapMarkers(localMarkers);
      setSavedIconState((v: any) => { return {...v, [project]: {project: project, icon: iconIter[selectedIcon], color: colors[selectedColor] } } })
    }
  }

  let getUserPrefs = async ()=>{
    let FOMMapViewState = await Storage.get({ key: 'FOMMapViewState' });
    if (FOMMapViewState.value) {
      let viewStateObj = JSON.parse(FOMMapViewState.value);
      setActiveTab(viewStateObj.activeTab);
      setProjectIsSelected(viewStateObj.projectIsSelected);
      setSavedIconState(viewStateObj.iconState)
    }
    else {
      let localProjectIsSelected:StringIter = {};
      for (let project in mapMarkers.projectDisplaynames) {
        localProjectIsSelected[project] = false;
      }
      localProjectIsSelected.scheduled = false;
      localProjectIsSelected.unscheduled = false;
      setProjectIsSelected(localProjectIsSelected);
    }
  }
  let saveUserPrefs = async (activeTab: any, projectIsSelected: any, savedIconState: any)=> {
    Storage.set({key: "FOMMapViewState", value: JSON.stringify({activeTab: activeTab, projectIsSelected: projectIsSelected, iconState: savedIconState})});
  }

  useEffect(() => {
    getUserPrefs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    saveUserPrefs(activeTab, projectIsSelected, savedIconState);
  }, [activeTab, projectIsSelected, savedIconState]);

  useEffect(() => {
    let localVisibleProjects:any = [];
    for (let project in projectIsSelected) {
      if (savedIconState[project]) {
        setIcon({}, savedIconState[project])
      }
      if (projectIsSelected[project]) {
        if (projectIsSelected.scheduled && mapMarkers[project]) {
          for (let marker in mapMarkers[project].scheduled) {
            if (!localVisibleProjects.includes(project))
              localVisibleProjects.push(project);
            mapMarkers[project].scheduled[marker].setMap(mapMarkers.map);
          }
        }
        else if (!projectIsSelected.scheduled && mapMarkers[project]) {
          for (let marker in mapMarkers[project].scheduled) {
            mapMarkers[project].scheduled[marker].setMap(null);
          }
        }
        if (projectIsSelected.unscheduled && mapMarkers[project]) {
          for (let marker in mapMarkers[project].unscheduled) {
            if (!localVisibleProjects.includes(project))
              localVisibleProjects.push(project);
            mapMarkers[project].unscheduled[marker].setMap(mapMarkers.map);
          }
        }
        else if (!projectIsSelected.unscheduled && mapMarkers[project]) {
          for (let marker in mapMarkers[project].unscheduled) {
            mapMarkers[project].unscheduled[marker].setMap(null);
          }
        }
      }
      else {
        if (mapMarkers[project]) {
          for (let marker in mapMarkers[project].scheduled) {
            mapMarkers[project].scheduled[marker].setMap(null);
          }
        }
        if (mapMarkers[project]) {
          for (let marker in mapMarkers[project].unscheduled) {
            mapMarkers[project].unscheduled[marker].setMap(null);
          }
        }
      }
    }
    setVisibleProjects(localVisibleProjects.slice());
    if (mapMarkers.map)
      setBounds(mapMarkers, mapMarkers.map);
  // eslint-disable-next-line
  }, [projectIsSelected, mapMarkers, savedIconState]);

  let [visible, setVisible] = useState<StringIter>({});
  let [allHidden, setAllHidden] = useState<StringIter>({});

  useEffect(() => {
    let localVisible:StringIter = {}
    for (let project in visibleProjects) {
      localVisible[project] = false;
    }
    setVisible(localVisible);
    setAllHidden(localVisible)
  }, [visibleProjects])


  useEffect(() => {
    let localProjects = [];
    for (let project in mapMarkers.projectDisplaynames) {
      localProjects.push(mapMarkers.projectDisplaynames[project]);
    }
    localProjects.sort();
    setProjectNames(localProjects.slice());
  }, [mapMarkers])

  useEffect(() => {
    var markerHover = (e:any, marker: any) => {
      e.stop();
      let delay = setTimeout (() => {
        if (!marker.install)
          return
        setActiveTab("installs");
        setVisible(allHidden);
        let project = marker.install["Brand & Project"];
        setVisible(v => {return {...v, [project]: true} });
        setTimeout(() => {
          let install = document.getElementById(marker.key)
          if (install) {
            install.classList.add("highlightedInstall");
            let accordion = document.getElementById(project + "accordion");
            if (accordion)
              accordion.scrollTop = install.offsetTop;
          }
          else {
            console.log('no install');
          }
        }, 100)
      }, 500)
      marker.delay = delay;
    }
    var endMarkerHover = (e:any, marker: any) => {
      e.stop();
      if (marker.delay)
        clearTimeout(marker.delay)
      if (!marker.install)
        return
      let install = document.getElementById(marker.key)
      if (install && install.classList.contains("highlightedInstall")) {
        install.classList.remove("highlightedInstall");
      }
      setTimeout(() => {
        if (install && install.classList.contains("highlightedInstall")) {
          install.classList.remove("highlightedInstall");
        }
      }, 500)
    };

    var markerClicked = (e:any, marker: any) => {
      let iconIter: StringIter = Icons;
      e.stop();
      let items = selectedInstalls.filter((item:any) => item.key === marker.key);
      if (items.length > 0 || selectedInstalls.length >= 27)
        return
      let ele = document.querySelector(`img[src*="${marker.store}${marker.install.Address}${marker.install.City}marker"]`);
      if (ele && ele.parentElement)
        ele.parentElement.classList.add("selectedInstall")

      if (typeof marker.icon === "object") {
        marker.icon.defaultPath = marker.icon.path
        marker.icon.path = iconIter["mdiMapMarkerCheckOutline"]
        marker.icon.scale = 2.5;
        marker.setMap(null);
        marker.setMap(mapMarkers.map);
      }
      setSelectedInstalls((v:any) => { return [...v, marker] });
      setDialogBoxIsVisible(true);
    };
    for (let project in mapMarkers) {
      if (mapMarkers[project].scheduled) {
        // eslint-disable-next-line
        [...mapMarkers[project].scheduled, ...mapMarkers[project].unscheduled].forEach((marker, index) => {
          for (let listener in marker.listeners) {
            google.maps.event.removeListener(marker.listeners[listener]);
          }
          let mouseOverListener = marker.addListener('mouseover', (e:any) => markerHover(e, marker));
          let mouseOutListener = marker.addListener('mouseout', (e:any) => endMarkerHover(e, marker));
          let clickListener = marker.addListener('click', (e:any) => markerClicked(e, marker));
          marker.listeners = {mouseOverListener, mouseOutListener, clickListener}
        });
      }
    }

    if (mapMarkers.installerHomes) {
      mapMarkers.installerHomes.forEach((marker: any, index: any) => {
        for (let listener in marker.listeners) {
          google.maps.event.removeListener(marker.listeners[listener]);
        }
        let clickListener = marker.addListener('click', (e:any) => markerClicked(e, marker));
        marker.listeners = {clickListener}
      });
    }
  }, [mapMarkers, allHidden, setDialogBoxIsVisible, selectedInstalls, setSelectedInstalls])

  let installsClick = (e: any, marker: any) => {
    let iconIter: StringIter = Icons;
    let items = selectedInstalls.filter((item:any) => item.key === marker.key);
    if (items.length > 0 || selectedInstalls.length >= 27)
      return;

    if (typeof marker.icon === "object") {
      marker.icon.defaultPath = marker.icon.path
      marker.icon.path = iconIter["mdiMapMarkerCheckOutline"]
      marker.icon.scale = 2.5;
      marker.setMap(null);
      marker.setMap(mapMarkers.map);
    }

    let ele = document.querySelector(`img[src*="${marker.store}${marker.install.Address}${marker.install.City}marker"]`);
    if (ele && ele.parentElement)
      ele.parentElement.classList.add("selectedInstall")
    setSelectedInstalls((v:any) => { return [...v, marker] });
    setDialogBoxIsVisible(true);
  }





  let iconState = {savedIconState, setIcon, modalVisible, setModalVisible, selectedIcon, setSelectedIcon, selectedColor, setSelectedColor, editTarget, setEditTarget}

  return (
    <IonMenu contentId="mapContent">
      <IonContent>
        <IonReactRouter>
          <IonTabs>
            <IonTabBar slot="top">
              <IonTabButton class="tabButton" selected={activeTab==="visibility"} tab="visibility" onClick={()=>setActiveTab("visibility")}>
                <IonLabel>Visibility</IonLabel>
                <IonIcon icon={funnel}  />
              </IonTabButton>
              <IonTabButton class="tabButton" selected={activeTab==="installs"} tab="visibility" onClick={()=>setActiveTab("installs")}>
                <IonLabel>Project Installs</IonLabel>
                <IonIcon icon={filing}  />
              </IonTabButton>
            </IonTabBar>
            <IonRouterOutlet>
              <Route path="/" render={props => {
                if (activeTab === "visibility") {
                  return <MenuVisibility {...{mapMarkers, projectNames, projectIsSelected, setProjectIsSelected}} />
                }
                if (activeTab === "installs") {
                  return <MenuInstalls {...iconState} {...{installsClick, visible, setVisible, allHidden, setMapMarkers, mapMarkers, projectNames, projectIsSelected, setProjectIsSelected, visibleProjects}}/>
                }
              }}/>
            </IonRouterOutlet>
          </IonTabs>
        </IonReactRouter>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
