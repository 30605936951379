import { IonAlert } from '@ionic/react';
import React, { useRef, useEffect, useState } from 'react';
import MapData from '../data/MapData';

const Map: React.FC<any> = ({setMapMarkers, setSelectedInstalls, selectedInstalls, setDialogBoxIsVisible}) => {
  const mapEle = useRef<HTMLDivElement>(document.createElement('div'));
  const map = useRef<google.maps.Map>();
  let mapData = useRef<MapData>(new MapData());

  let [showAlert, setShowAlert] = useState(false);
  let [customMarkerNumber, setCustomMarkerNumber] = useState(1);
  let [customMarkerPosition, setCustomMarkerPosition] = useState<any>(null);

  let mapClicked = (e: any) => {
    setCustomMarkerPosition(e.latLng)
    setShowAlert(true);
  }
  useEffect(() => {
    let position = {"status":"OK","lat":39.8283,"lng":-98.5795};
    map.current = new google.maps.Map(mapEle.current, {
      center: position,
      zoom: 5
    });
    if (map.current) {
      mapData.current.processData(map.current, setMapMarkers);
      map.current.addListener(`click`, mapClicked)
    }
    // eslint-disable-next-line
  }, [])

  var markerClicked = (e:any, marker: any) => {
    e.stop();
    let items = selectedInstalls.filter((item:any) => item.key === marker.key);
    if (items.length > 0)
      return
    let ele = document.querySelector(`img[src*="${marker.store}${marker.install.Address}${marker.install.City}marker"]`);
    if (ele && ele.parentElement)
      ele.parentElement.classList.add("selectedInstall")
    setSelectedInstalls((v:any) => { return [...v, marker] });
    setDialogBoxIsVisible(true);
  };

  let createCustomMarker = () => {
    var marker:any = new google.maps.Marker({
      position: customMarkerPosition,
      map: map.current,
      label: customMarkerNumber.toString(),
//                        icon: "http://maps.google.com/mapfiles/marker" + labels[labelIndex++ % labels.length] + ".png"
    });
    marker.store = customMarkerNumber
    marker.install = {Store: "Custom Marker ", City: customMarkerNumber, Address: customMarkerNumber, State: ""};
    marker.key = marker.store + marker.install.Address + marker.install.City
    marker.addListener("click", function (e:any) {
      markerClicked(e, marker)
    })
    setCustomMarkerNumber(customMarkerNumber + 1);
  }
  return (
    <>
      <div ref={mapEle} className="map-canvas"></div>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Custom Marker'}
        message={'This will add a custom Marker.'}
        buttons={['Cancel',
            { text: 'Okay',
              handler: createCustomMarker
            }]}
      />
    </>
  );
}

export default Map;
