import React, {useState, useEffect} from 'react';
import { IonApp, IonPage } from '@ionic/react';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.scss';
import applyTheme, { blueTheme } from './theme/themeGenerator';

import FloatingDialog from './components/FloatingDialog';

const App: React.FC = () => {
  let [mapsApiLoaded, setMapsApiLoaded] = useState(false);
  let [dialogBoxIsVisible, setDialogBoxIsVisible] = useState(false);
  let [selectedInstalls, setSelectedInstalls] = useState([]);
  let [mapMarkers, setMapMarkers] = useState({});
  let [appColors, setAppColors] = useState({});

  let state = {
    mapsApiLoaded, setMapsApiLoaded, dialogBoxIsVisible, setDialogBoxIsVisible, selectedInstalls, setSelectedInstalls,
    mapMarkers, setMapMarkers, appColors
  }

  useEffect(() => {
    let checkMapsApiLoaded = setInterval(check, 100);
    function check () {
      if (typeof google !== "undefined") {
        setMapsApiLoaded(true);
        clearInterval(checkMapsApiLoaded);
      }
    };
    setAppColors(applyTheme(blueTheme));
  }, [])

  return(
    <>
    <IonApp>
      {
        !mapsApiLoaded ? <IonPage></IonPage> : <Home {...state}/>
      }
    </IonApp>
      {
        !mapsApiLoaded ? <IonPage></IonPage> : <FloatingDialog {...state}/>
      }
    </>
  )
};

export default App;
