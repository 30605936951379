import { IonGrid, IonRow, IonCol } from '@ionic/react';
import React, {} from 'react';
import './SelectedListItem.scss';


 const SelectedListItem: React.FC<any> = (props:any) => {
  let marker = props.marker;
  let appColors = props.appColors;

  function mouseEnter(e: any, marker: any) {
    e.target.style.background = appColors["--ion-color-danger"];
    e.target.style.color = appColors["--ion-color-danger-contrast"];
    let delay = setTimeout(() => {
      let ele = document.querySelector(`img[src*="${marker.store}${marker.install.Address}${marker.install.City}marker"]`);
      if (ele && ele.parentElement)
        ele.parentElement.classList.add("hoveredInstall")
      if (marker.setAnimation)
        marker.setAnimation(google.maps.Animation.BOUNCE);
    }, 50);
    marker.delay = delay;
  }
  function mouseLeave(e: any, marker: any) {
    e.target.style.background = "";
    e.target.style.color = "";
    if (marker.setAnimation)
      marker.setAnimation();
    if (marker.delay)
      clearTimeout(marker.delay)
    let ele = document.querySelector(`img[src*="${marker.store}${marker.install.Address}${marker.install.City}marker"]`);
    if (ele && ele.parentElement)
      ele.parentElement.classList.remove("hoveredInstall")
    setTimeout(() => {
      marker.setAnimation();
      let ele = document.querySelector(`img[src*="${marker.store}${marker.install.Address}${marker.install.City}marker"]`);
      if (ele && ele.parentElement)
        ele.parentElement.classList.remove("hoveredInstall")
    }, 500)
  }

  return (
    <IonGrid
    class="ion-no-margin ion-no-padding"><IonRow class="ion-no-margin ion-no-padding"><IonCol  class="ion-no-margin ion-no-padding">
      <IonGrid
      class="ion-no-margin ion-no-padding">
        <div>
        <IonRow
        class="ion-align-items-center ion-no-margin ion-no-padding">
            <IonCol
              class="ion-text-center ion-no-margin text1 draggable"
              onDragStart={props.onDragStart}
              onDragOver={props.onDragOver}
              onDragEnd={props.onDragEnd}
              onClick={(e) => {props.onClick(e, marker)}}
              onMouseEnter={(e) => {mouseEnter(e, marker);}}
              onMouseLeave={(e) => {mouseLeave(e, marker);}}
              draggable>
              {marker.install.Store + " - "}
              {marker.install.City + ", "}
              {marker.install.State}
            </IonCol>
        </IonRow>
        </div>
      </IonGrid>
      </IonCol></IonRow>
      <IonRow class="ion-no-margin ion-no-padding">
        <IonCol class="ion-no-padding ion-no-margin text2">
          <div className="ion-text-center" dangerouslySetInnerHTML={{__html: props.directions}}>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default SelectedListItem;
