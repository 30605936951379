import { IonContent, IonSplitPane} from '@ionic/react';
import React from 'react';

import MapView from './MapView';
import Menu from '../components/Menu';

import './Home.scss';

const Home: React.FC<any> = (props) => {


  return (
      <IonContent className="ion-padding">
        <IonSplitPane contentId="mapContent">
          <Menu {...props}/>
          <MapView {...props}/>
        </IonSplitPane>
      </IonContent>
  );
};

export default Home;
