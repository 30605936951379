//import { Dispatch, SetStateAction } from 'react';

import RouteManager from './RouteManager';

interface StringIter {
  [index: string]: any,
}

export default class MapData {
  routeManager?:RouteManager;
  map?: google.maps.Map;
  markers: any;
  accordions: any;
  environment: any;
  headings: StringIter;
  installs: any;

  constructor() {


    this.markers = {};
    this.installs = {};
    this.accordions = {length: 0};
    this.environment = {
      visibility: { unscheduled: true },
      accordion: { controls: 0, installsAccordion: false }
    };

    this.headings = {
      "Brand & Project": "Brand & Project",
      "Store": "Store",
      "Address": "Address",
      "City": "City",
      "State": "State",
      "Install Team": "Install Team",
      "Install Date": "Install Date",
      "point": "point",
      "Omit": "Hide?",
      "warehouse": "warehouse",
    }
  }

  async processData(map: google.maps.Map, setMapMarkers: any) {
    this.map = map;

    let url = "https://bradleygraber.com/FOMMap/js/masterMap.json";

    let masterMapData = await fetch(url)
                    .then((json) => {
                      if (json.status === 200)
                        return json.json();
                      else {
                      }
                    })

    let projects:any = [];
    let projectDisplaynames:any = {};
//    let tables:any = {};

    // Populate Installer home locations
    let installerHomes: google.maps.Marker[]  = [];
    for (let installer in masterMapData.installerData) {
      if (masterMapData.installerData[installer].home === "")
        continue;
      var enclosure = (google: any) => {
        var label = installer.substring(installer.indexOf("-")+1);
        var marker = new google.maps.Marker({
                  position: new google.maps.LatLng(masterMapData.installerData[installer].home),
                  map: this.map,
                  icon: "assets/icon/blank_sgreen_wide.png",
                  label: label,
                });
        marker.store = installer
        marker.install = {Store: "Installer ", City: installer, Address: installer, State: ""};
        marker.key = marker.store + marker.install.Address + marker.install.City
        installerHomes.push(marker);
      }
      enclosure(google);
    }

    // Populate Install data
    masterMapData.installs.forEach((install:any) => {
      let projectDisplayname = install[this.headings["Brand & Project"]]
      let project = projectDisplayname
      if (projects.indexOf(project) === -1) {
        if (project === "")
          return
        projects.push(project);
        projectDisplaynames[project] = projectDisplayname;

        this.installs[project] = {scheduled: [], unscheduled: []};
        this.markers[project] = {scheduled: [], unscheduled: []};

//        tables[project] = `<table style='border-color: #FFFAF0; border-collapse: collapse; width: 100%; color: #FFFAF0;'><tr id="tableHead">`;
//        for (heading in this.headings)
//        if (heading != "Brand & Project" && heading != "City" && heading != "State" && heading != "point" && heading != "warehouse" && heading !="Omit")
//            tables[project] += `<th>${this.headings[heading]}</th>`;
//        tables[project] += "</tr>"
      }
      let date = install[this.headings["Install Date"]];
      date = date.replace(/<br>/g, "\n");
      install[this.headings["Install Date"]] = date;
      this.addMarker(install, this.headings);

//      var border = "border: 1px solid #FFFAF0; border-collapse: collapse; padding: 4px; color: #FFFAF0;"
//      var centered = "text-align:center;"

//      let scheduled = (install["Install Team"] !== "" && install["Install Date"] !== "") ? "scheduled" : "unscheduled";
//      let store = install[this.headings["Brand & Project"]].toString() + "-" + install[this.headings["Store"]].toString();
//      install[this.headings["Store"]] = store.replace(/\s/g,'_')
//      var tr = `<tr id="${install[this.headings["Store"]]}" data-store="${install[this.headings["Store"]]}" data-point="${utility.encode(install[this.headings["point"]])}" data-address="${utility.encode(install[this.headings["Address"]])}" style='${border} display: none;' class='${scheduled}'">`
//      tables[project] += tr
/**
      for (heading in this.headings)
        if (heading != "Brand & Project" && heading != "City" && heading != "State" && heading != "point" && heading != "warehouse" && heading !="Omit")
          if (heading == "Address")
            tables[project] += `<td style="${border}${centered}">${install[this.headings[heading]]}<br>${install[this.headings["City"]]}, ${install[this.headings["State"]]}</td>`
          else if (heading == "Store")
            tables[project] += `<td style="${border}${centered}">${install[this.headings[heading]].toString().replace(/_/g,'')}</td>`
          else
            tables[project] += `<td style="${border}${centered}">${install[this.headings[heading]]}</td>`
      tables[project] += `</tr>`
**/
    })
    this.markers.projectDisplaynames = projectDisplaynames;
    this.markers.map = this.map;
    this.markers.installs = this.installs;
    this.markers.installerHomes = installerHomes;
    setMapMarkers(this.markers);
  }

  setIconPath(store:string) {
    var icons:StringIter = {
      PanTC: {icon: `assets/icon/letter_p_purple.png#${store}marker`},
      PanTile: {icon: `assets/icon/lambda_purple.png#${store}marker`},
      TBNL: {icon: `assets/icon/star_red.png#${store}marker`},
      TBFlex: {icon: `assets/icon/star_orange.png#${store}marker`},
      TBFruit: {icon: `assets/icon/letter_f_purple.png#${store}marker`},
      Coke: {icon: `assets/icon/letter_c_red.png#${store}marker`},
      Chilis: { icon: `assets/icon/letter_c_teal.png#${store}marker`},
      TBProbin: { icon: `assets/icon/letter_p_yellow.png#${store}marker`},
      TBPBFab: { icon: `assets/icon/letter_p_red.png#${store}marker`},
      TBFryer: { icon: `assets/icon/letter_f_blue.png#${store}marker`},
      TBRethrm: { icon: `assets/icon/almost_blue.png#${store}marker`},
      TBKiosk: { icon: `assets/icon/letter_k_blue.png#${store}marker`},
      TBRetro: { icon: `assets/icon/star_blue.png#${store}marker`},
      PanSurvey: { icon: `assets/icon/zoom_purple.png#${store}marker`},
      RubyTue: { icon: `assets/icon/letter_r_green.png#${store}marker`},
      Pollo: { icon: `assets/icon/letter_p_cyan.png#${store}marker`},
      unknown: { icon: `assets/icon/unknown_grey.png#${store}marker`},
      PaneraUnknown: { icon: `assets/icon/question_purple.png#${store}marker`},
      TBUnknown: { icon: `assets/icon/question_blue.png#${store}marker`},
      warehouse: {icon: `assets/icon/blank_teal.png#${store}Wmarker`}
    }
    return icons
  }


  addMarker(install: any, headings: any) {
    var projectDisplayname = install[headings["Brand & Project"]]
    var project = projectDisplayname
    var icons = this.setIconPath(install[headings["Store"]] + install[headings["Address"]] + install[headings["City"]])

    var warehouse:any = undefined;
    var warehouseLine = undefined;
    if (install[headings["warehouse"]]) {
      warehouse = new google.maps.Marker({
        position: install[headings["warehouse"]],
  //        icon: "icons/blank_teal.png",
      });
      warehouse.warehouse = true;
      warehouse.store = install[headings["Store"]] + "WH";
      warehouse.project = project;
      warehouse.install = {...install};
      warehouse.install.Store = warehouse.install.Store + " WH"
      warehouse.install.Address = warehouse.install["Ship To Address"];
      warehouse.install.City = warehouse.install["Ship To City"];
      warehouse.install.State = warehouse.install["Ship To State"];
      warehouse.key = JSON.stringify(install) + "warehouse";
      warehouse.map = this.map;
      warehouse.setOptions(icons["warehouse"]);
  //      warehouse.store = install[headings["Store"]] + "Warehouse"
      warehouse.addListener('click', function (e: any) {
//        this.installSelected(event, warehouse)
      });
      warehouse.addListener('mouseover', function (e: any) {
//        this.markerHover(event, marker)
      });
      warehouse.addListener('mouseout', function (e: any) {
//        this.endMarkerHover(event, marker)
      });
      var linePath = [
        install[headings["warehouse"]],
        install[headings["point"]],
      ];
      warehouseLine = new google.maps.Polyline({
        path: linePath,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      });
    }

    var marker:any = new google.maps.Marker();

    if (icons[project])
      marker.setOptions(icons[project]);
    else {
      if (project.indexOf("Pan") !== -1)
        marker.setOptions(icons.PaneraUnknown);
      else if (project.indexOf("TB") !== -1)
        marker.setOptions(icons.TBUnknown);
      else
        marker.setOptions(icons.unknown);
    }

    marker.setPosition(install[headings["point"]]);
    marker.store = install[headings["Store"]];
    marker.project = project;
    marker.install = install;
    marker.key = JSON.stringify(install);
    marker.map = this.map;
    marker.addListener('click', function (e: any) {
//      view.installSelected(event, marker)
    });
    marker.addListener('mouseover', function (e: any) {
//      view.markerHover(event, marker)
    });
    marker.addListener('mouseout', function (e: any) {
//      view.endMarkerHover(event, marker)
    });

    if (install[headings["Install Team"]] !== "" && install[headings["Install Date"]] !== ""){
      this.markers[project].scheduled.push(marker);
      this.installs[project].scheduled.push(install);
      if (warehouse) {
        this.markers[project].scheduled.push(warehouse);
        this.markers[project].scheduled.push(warehouseLine);
      }
    }
    else {
      this.markers[project].unscheduled.push(marker);
      this.installs[project].unscheduled.push(install);
      if (warehouse){
        this.markers[project].unscheduled.push(warehouse);
        this.markers[project].unscheduled.push(warehouseLine);
      }
    }
  }

  installSelected(e: any, marker: any) {
    console.log(marker.label);
  }
}

export function setBounds(markers: any, map: any) {
  var bounds = new google.maps.LatLngBounds();
  for (var project in markers) {
    if (!markers[project].scheduled)
      continue;
    markers[project].scheduled.forEach(function (marker: any) {
      if (marker.getMap() && marker.getPosition) {
        bounds.extend(marker.getPosition())
      }
    });
    markers[project].unscheduled.forEach(function (marker: any) {
      if (marker.getMap() && marker.getPosition) {
        bounds.extend(marker.getPosition())
      }
    });
  }
  let e = document.getElementById("mapContent");

  if (e) {
    let cs = window.getComputedStyle(e);

    if (cs && cs.height) {
      var interval = setInterval(() => {
        setView(cs);
      }, 100);
    }
  }
  function setView(cs: any) {
    if (cs.height !== "auto") {
      let height = parseFloat(cs.height);
      let width = parseFloat(cs.width);
      let zoom = getBoundsZoomLevel(bounds, {height: height, width: width});
      if (!bounds.isEmpty()) {
        map.setCenter(bounds.getCenter());
        map.setZoom(zoom);
        if (map.getZoom() > 10)
          map.setZoom(10);
      }
      clearInterval(interval);
    }
  }



}

function getBoundsZoomLevel(bounds:any, mapDim: any) {
    var WORLD_DIM = { height: 256, width: 256 };
    var ZOOM_MAX = 21;

    function latRad(lat:any) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx:any, worldPx:any, fraction:any) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
}
