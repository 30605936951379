export default class RouteManager {
//  map: google.maps.Map;
  directionsService: google.maps.DirectionsService;
  directionsArray: any[];
  numOfRoutes: number;

  constructor() {
    this.directionsService = new google.maps.DirectionsService();
    this.directionsArray = [];
    this.numOfRoutes = 0
  }
  getOptimizedRoute(points: any[], map: any, optimizeWaypoints: any, setDirectionsResults: any) {
    if (points.length > 27) {
      var count = points.length - 27
      for (let i = 0; i < count; i++) {
        points.pop()
      }
    }
    var directionsDisplay = new google.maps.DirectionsRenderer({map: map});
    this.directionsArray.push(directionsDisplay)
    var waypoints = points.splice(1, points.length-2)
    var waypointsFormatted: any = [];
    waypoints.forEach(function (waypoint) {
      waypointsFormatted.push({location: waypoint, stopover: true})
    })
    this.directionsService.route({
              origin: points[0],
              destination: points[points.length-1],
              waypoints: waypointsFormatted,
              optimizeWaypoints: optimizeWaypoints,
              travelMode: google.maps.TravelMode.DRIVING
            }, function (response, status) {
              if (status === 'OK') {
                let panel = document.getElementById('output');
                panel = panel ? panel : document.createElement('div');
                directionsDisplay.setDirections(response);
                directionsDisplay.setOptions({
                  markerOptions: {visible: false},
                  panel: panel,
                  preserveViewport: true,
                })
              } else {
                window.alert('Directions request failed due to ' + status);
              }
              setTimeout(function () {
                let summary:any = document.getElementsByClassName("adp-summary");

                var totalMins = 0;
                var totalMiles:any = 0;
                let results = [];
                for (let line in summary) {
                  if (Number.isInteger(parseInt(line))) {
                    let string = summary[line].innerText.toString()
                    let days = string.match(/[0-9]+?(?= day)/)
                    let hours = string.match(/[0-9]+?(?= hour)/)
                    let mins = string.match(/[0-9]+?(?= min)/)
                    let miles = string.match(/[0-9.,]+?(?= mi)/)
                    miles = miles.toString().replace(/,/g,'')
                    if (days)
                      totalMins += parseInt(days[0]) * 60 * 24
                    if (hours) {
                      totalMins += parseInt(hours[0]) * 60
                    }
                    if (mins)
                      totalMins += parseInt(mins[0])
                    totalMiles += parseFloat(miles)
                    results.push(summary[line].innerHTML);
                  }
                }
                totalMiles = numberWithCommas(Math.round(totalMiles))
                results.push(`<span>Total: ${totalMiles} mi. - ${displayTime(totalMins * 60)}</span>`)
                const myNode = document.getElementById("output");
                while (myNode && myNode.firstChild) {
                  myNode.removeChild(myNode.firstChild);
                }
                setDirectionsResults(results);
              }, 300)
            });
  }
  getDirections (point1: any, point2: any) {
    /**
    this.numOfRoutes++
    var numOfRoutes = this.numOfRoutes
    var map = this.map
    this.routeInfo.dialog("open")
    var directionsDisplay = new google.maps.DirectionsRenderer({map: this.map});
    this.directionsArray.push(directionsDisplay)
    this.directionsService.route({
              origin: point1,
              destination: point2,
              travelMode: 'DRIVING'
            }, function (response, status) {
              if (status === 'OK') {
                directionsDisplay.setDirections(response);
                directionsDisplay.setOptions({
                  markerOptions: {visible: false},
                  panel: document.getElementById('output'),
                  preserveViewport: true,
                })

                setTimeout(function(){
                  var element = $("<span />").html(`${numOfRoutes}: `)
                  var summary = $(".adp-summary").clone()
                  summary.prepend(element)
                  summary.removeClass(".adp-summary")
                  this.routeInfoTrip.append(summary[0])
                  $("#output").empty()
                }, 50);
                setTimeout(function () {
                  var infoWindow = new google.maps.InfoWindow()
                  infoWindow.setOptions({
                    position: utility.midPoint(point1, point2),
                    content: `<p> Route: ${numOfRoutes}</p>`
                  })
//                  infoWindow.open(map)
                }, 50)
              } else {
                window.alert('Directions request failed due to ' + status);
              }
            });
      **/
  }
  clear () {
    this.directionsArray.forEach(function (route) {
      route.setMap(null)
    })
  }
}

function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function displayTime (a: any) {
	var days = Math.floor(a / 86400);
	var hours=Math.floor(a / 3600) - (days * 24);
	var minutes=Math.floor(a / 60) - (days * 1440) - (hours * 60);
//	var seconds=Math.floor(a % 60);

	if (days > 0)
		return days + "d " + hours + "h " + minutes + "m";
	return hours + "h " + minutes + "m";
}
